<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true">
				<span>
					<el-form-item label="期数">
						<el-select v-model="form.qishu" placeholder="请选择期数" clearable>
							<el-option v-for="(item,index) in qishuList" :key="index" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="日期">
						<el-date-picker	v-model="form.match_date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
					</el-form-item>
					<el-form-item label="状态">
						<el-select v-model="form.status" placeholder="请选择状态" clearable>
							<el-option v-for="(item,index) in statusList" :key="index" :label="item.value" :value="item.label"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
					</el-form-item>
				</span>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="id" label="ID" width="50" align="center">
			</el-table-column>
			<el-table-column prop="qishu" label="期数" width="60" align="center">
			</el-table-column>
			<el-table-column prop="game_name" label="比赛类型" width="80" align="center">
			</el-table-column>
			<el-table-column prop="zhudui_name" label="主队" align="center">
			</el-table-column>
			<el-table-column prop="kedui_name" label="客队" align="center">
			</el-table-column>
			<el-table-column label="比赛状态" width="100" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status == '0'">未开始可投注</span>
					<span v-if="scope.row.status == '10'">未开始截止投注</span>
					<span v-if="scope.row.status == '20'">上半场</span>
					<span v-if="scope.row.status == '30'">中场休息</span>
					<span v-if="scope.row.status == '40'">下半场</span>
					<span v-if="scope.row.status == '50'">比赛结束</span>
					<span v-if="scope.row.status == '99'">比赛腰斩</span>
					<span v-if="scope.row.status == '102'">比赛改期</span>
					<span v-if="scope.row.status == '110'">官方确定延期</span>
				</template>
			</el-table-column>
			<el-table-column prop="match_turn" label="竞猜轮次" width="50" align="center">
			</el-table-column>
			<el-table-column prop="match_date" label="比赛时间" width="80" align="center">
			</el-table-column>
			<el-table-column prop="start_time" label="开始时间" width="140" align="center">
			</el-table-column>
			<el-table-column prop="concede" label="让球数" width="50" align="center">
			</el-table-column>
			<el-table-column label="胜负平" align="center">
				<el-table-column label="彩果" width="50" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.zhujin > scope.row.kejin">胜</span>
						<span v-else-if="scope.row.zhujin == scope.row.kejin">平</span>
						<span v-else>负</span>
					</template>
				</el-table-column>
				<el-table-column prop="sp" label="赔率" width="60" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column label="比分结果" align="center">
				<el-table-column label="彩果" width="50" align="center">
					<template slot-scope="scope">
						{{scope.row.zhujin}} : {{scope.row.kejin}}
					</template>
				</el-table-column>
				<el-table-column prop="point_sp" label="赔率" width="60" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column label="半全场胜负平" align="center">
				<el-table-column label="彩果" width="70" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.half == 3">胜</span>
						<span v-else-if="scope.row.half == 1">平</span>
						<span v-else>负</span>
						/
						<span v-if="scope.row.whole == 3">胜</span>
						<span v-else-if="scope.row.whole == 1">平</span>
						<span v-else>负</span>
					</template>
				</el-table-column>
				<el-table-column prop="half_whole_sp" label="赔率" width="60" align="center">
				</el-table-column>
			</el-table-column>			
			<!-- <el-table-column label="让球胜负平" align="center">
				<el-table-column prop="result.handicap.desc" label="彩果" width="60" align="center">
				</el-table-column>
				<el-table-column prop="result.handicap.odds" label="赔率" width="60" align="center">
				</el-table-column>
			</el-table-column> -->
			<el-table-column label="总进球结果" align="center">
				<el-table-column label="彩果" width="50" align="center">
					<template slot-scope="scope">
						{{scope.row.zhujin  + scope.row.kejin}}
					</template>
				</el-table-column>
				<el-table-column prop="goals_sp" label="赔率" width="60" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column prop="single_double_sp" label="上下盘单双赔率" width="60" align="center">
			</el-table-column>			
			<el-table-column prop="game_name" label="操作" width="180" align="center">
				<template slot-scope="scope">
					<el-button type="primary" plain size="small" @click="goModify(scope.row)">修改数据</el-button>
					<el-button v-if="scope.row.sp && (scope.row.status == 50 || scope.row.status == 110)" type="primary" plain size="small" @click="setReopen(scope.row.id)">重新开奖</el-button>
				</template>
			</el-table-column>			
		</el-table>
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!-- <span>共计商户：7家,收款总额: 1688834.59元</span> -->
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>
		<el-dialog title="修改比赛数据" :visible.sync="dialogShow" width="760px" center>
			<h2 class="tc mb15">{{gameInfo.zhudui_name}} Vs {{gameInfo.kedui_name}}</h2>
			<el-form :model="gameInfo" label-width="120px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="比赛状态">
							<el-select v-model="gameInfo.status" placeholder="请选择状态" clearable>
								<el-option v-for="(item,index) in statusList" :key="index" :label="item.value" :value="item.label"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="半场赛果">
							<el-select v-model="gameInfo.half" placeholder="请选择状态" clearable>
								<el-option v-for="(item,index) in halfList" :key="index" :label="item.value" :value="item.label"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="完场赛果">
							<el-select v-model="gameInfo.whole" placeholder="请选择状态" clearable>
								<el-option v-for="(item,index) in halfList" :key="index" :label="item.value" :value="item.label"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="主队进球">
							<el-input type="number" min="0" v-model="gameInfo.zhujin" placeholder="20个字符" class="w1"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="客队进球">
							<el-input type="number" min="0" v-model="gameInfo.kejin" placeholder="20个字符" class="w1"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="赛果确认次数">
							<el-input type="number" v-model="gameInfo.match_result_count" placeholder="20个字符" class="fl w1 mr10"></el-input>
							<p class="fl yellow">确认次数大于等于2，则数据比对成功，可开奖。 </p>
						</el-form-item>						
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="goEdcDialog()">提 交</el-button>
				<el-button @click="dialogShow = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog title="确认修改数据" :visible.sync="edcDialog" width="560px" center>
			<h2 class="mb15">{{gameInfo.zhudui_name}} Vs {{gameInfo.kedui_name}}的比赛修改数据如下：</h2>
			<el-form :model="gameInfo" label-width="120px">
				<el-row class="mb10">
					<el-col :span="12">					
						<p>比赛状态：
							<strong v-if="gameInfo.status == '0'">未开始可投注</strong>
							<strong v-if="gameInfo.status == '10'">未开始截止投注</strong>
							<strong v-if="gameInfo.status == '20'">上半场</strong>
							<strong v-if="gameInfo.status == '30'">中场休息</strong>
							<strong v-if="gameInfo.status == '40'">下半场</strong>
							<strong v-if="gameInfo.status == '50'">比赛结束</strong>
							<strong v-if="gameInfo.status == '99'">比赛腰斩</strong>
							<strong v-if="gameInfo.status == '102'">比赛改期</strong>
							<strong v-if="gameInfo.status == '110'">官方确定延期</strong>
						</p>
					</el-col>
				</el-row>
				<el-row class="mb10">
					<el-col :span="12">
						<p>半场赛果：<strong v-if="gameInfo.half == 3">胜</strong>
					<strong v-if="gameInfo.half == 1">平</strong>
					<strong v-if="gameInfo.half == 0">负</strong></p>
					</el-col>
					<el-col :span="12">
						<p>完场赛果：<strong v-if="gameInfo.whole == 3">胜</strong>
					<strong v-if="gameInfo.whole == 1">平</strong>
					<strong v-if="gameInfo.whole == 0">负</strong></p>
					</el-col>
				</el-row>
				<el-row class="mb10">
					<el-col :span="12">
						<p>主队进球：<strong>{{gameInfo.zhujin}} 球</strong></p>
					</el-col>
					<el-col :span="12">
						<p>客队进球：<strong>{{gameInfo.kejin}} 球</strong></p>
					</el-col>
				</el-row>
				<el-row class="mb10">
					<el-col :span="24">
						<p>赛果确认次数：<strong>{{gameInfo.match_result_count}}</strong></p>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="setModify()">提 交</el-button>
				<el-button @click="goDialogShow()">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	import {
		bdList,modify,reopen,qishu
	} from '../../api/api';

	export default {
		data() {
			return {
				listData: [],
				form:{
					qishu:'',
					match_date: '',
					status: '',
					lottery_type:'2'
				},
				qishuList:[],
				statusList:[
					{
						value:'未开始可投注',
						label: '0',
					},{
						value:'未开始截止投注',
						label: '10',
					},{
						value:'上半场',
						label: '20',
					},{
						value:'中场休息',
						label: '30',
					},{
						value:'下半场',
						label: '40',
					},{
						value:'比赛结束',
						label: '50',
					},{
						value:'比赛腰斩',
						label: '99',
					},{
						value:'比赛改期',
						label: '102',
					},{
						value:'官方确定延期',
						label: '110',
					}
				],
				halfList:[
					{
						value:'胜',
						label: '3',
					},{
						value:'平',
						label: '1',
					},{
						value:'负',
						label: '0',
					}
				],
				gameInfo:{},
				dialogShow: false,
				edcDialog: false,
				listLoading: false,
				//分页
				total: 0,
				page: 1,
				pagesize: 20,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
			}
		},
		methods: {
			goDialogShow(){
				this.edcDialog = false;
				this.dialogShow = true
			},
			goEdcDialog(){
				this.edcDialog = true;
				this.dialogShow = false
			},
			goModify(data){
				this.gameInfo = this.deepCopy(data);
				this.dialogShow = true
			},
			// 修改数据
			setModify(){				
				var _t = this;
				_t.edcDialog = false;
				let para = {
					id: _t.gameInfo.id,
					status: _t.gameInfo.status,
					half: _t.gameInfo.half,
					whole: _t.gameInfo.whole,
					zhujin: _t.gameInfo.zhujin,
					kejin: _t.gameInfo.kejin,
					match_result_count: _t.gameInfo.match_result_count,
					lottery_type:'2'
				};
				modify(para).then((res) => {
					if (res.code == 1) {
						_t.getList();
					} else {
						_t.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			// 重新开奖
			setReopen(id){				
				var _t = this;
				let para = {
					id: id,
					lottery_type:'2'
				};
				reopen(para).then((res) => {
					if (res.code == 1) {
						_t.$notify({
							title: '重置成功'
						});
					} else {
						_t.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			getQishu(){
				qishu({lottery_type:'2'}).then((res) => {
					if (res.code == 1) {
						this.qishuList = res.data;
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//获取数据
			getList(val) {
				if (val == '0') this.page = 1
				let para = {
					...this.form,
					pagesize: this.pagesize,
					page: this.page
				};
				this.listLoading = true;
				bdList(para).then((res) => {
					if (res.code == 1) {
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
		},
		mounted() {
			//获取列表数据
			this.getQishu();
			this.getList();
		}
	}
</script>

<style lang="scss" scoped>
	.w1{ width:217px }
</style>
